import { AD_IDS, isClient } from "@constants/consts";
import featureTooling, { FeatureSlugs } from "@utils/common/featureTooling";
import getHostConfig from "@utils/common/getHostConfig";

const articleSlots = [AD_IDS.topBanner, AD_IDS.contentBanner, AD_IDS.bottomBanner, AD_IDS.articlePanelBanner];
const videoSlots = [AD_IDS.contentBanner, AD_IDS.bottomBanner];

export async function fetchDpgAds(url: string): Promise<{ html: string | null; requestBody?: DpgAdsRequest } | null> {
  if (!featureTooling.isEnabled(FeatureSlugs.DISPLAY_ADS)) return null;
  const [path] = url.split("?");
  const pageType = path.includes("/videos/") ? "video" : "article";
  const fullNavigation = path.split("/").filter(Boolean);

  const componentId = fullNavigation[fullNavigation.length - 1];

  if (isClient && window.dpgPreviousRequest?.componentId === componentId) {
    // skip request when same request was sent last
    window.eventBus.dispatch("triggerAd", {});
    return null;
  }

  const navigation = fullNavigation.slice(0, -1);
  const { displayAdsEndpoint, dpgApiKey } = getHostConfig();

  const previousNavigation = isClient ? window.dpgPreviousRequest : undefined;

  const body = {
    slots: pageType === "article" ? articleSlots : videoSlots,
    pageType,
    navigation,
    componentId,
    previousPageInfo: previousNavigation,
  };

  try {
    const response = await fetch(displayAdsEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": dpgApiKey,
      },
      body: JSON.stringify(body),
    });

    if (isClient) window.dpgPreviousRequest = body;

    const adHtml = await response.json();

    return { html: adHtml.html, requestBody: body };
  } catch (err) {
    console.log(err);
    return { html: null };
  }
}

export function injectSnippet(html: string | null) {
  if (!html) return;
  document.head.appendChild(document.createRange().createContextualFragment(html));
}
