import { IncomingMessage, ServerResponse } from "http";
import { ParsedUrlQuery } from "querystring";

export const createRedirectResponse = ({
  res,
  query,
  url,
}: {
  res: ServerResponse<IncomingMessage>;
  url: string;
  query?: ParsedUrlQuery;
}) => {
  const inAppQueryParam = query?.inApp === "true" ? `?inApp=${query.inApp === "true"}` : "";
  res.writeHead(301, { Location: `${url}${inAppQueryParam}` });
  res.end();
  return {};
};
