import React, { useEffect } from "react";
import type { NextPage, NextPageContext } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import { EventLog } from "@4tn/webx-analytics";
import { useQuery } from "@tanstack/react-query";
import parse from "html-react-parser";
import ArticleContent from "@pageContent/ArticleContent";
import OopsPage from "@pageContent/OopsPage";
import StructuredData from "@pageContent/StructuredData";
import { tableNames } from "@constants/consts";
import { createRedirectResponse } from "@utils/common/createRedirectResponse";
import { useHostConfig } from "@utils/common/getHostConfig";
import { useNoticeError } from "@utils/common/newRelic";
import usePageView from "@utils/common/usePageView";
import { fetchDpgAds, injectSnippet } from "@utils/pageContent/displayAds";
import { fetchByEndpoint } from "@utils/pageContent/fetchData";

const ArticleMetadata: React.FC<{ article?: Article }> = ({ article }) => {
  const { outletName } = useHostConfig();

  if (!article) return null;

  const { structuredData, category, title } = article;

  return (
    <>
      <StructuredData structuredData={structuredData} />
      <Head>
        <title>{`${title} | ${outletName}`}</title>
        <meta property="og:title" content={article.title} />
        <meta property="og:type" content="article" />
        {category && <meta property="article:section" content={category.title} />}
      </Head>
    </>
  );
};

const ArticlePage: NextPage<{
  initialArticle?: Article;
  initialAds?: DpgAdsResponse | null;
}> = ({ initialArticle, initialAds }) => {
  const { query, asPath } = useRouter();

  const slugs = Array.isArray(query.slugs) ? query.slugs : [];
  const articleSlug = slugs[slugs.length - 1];

  const articleQueryKey = ["article", articleSlug];

  const {
    isLoading,
    data: article,
    isError,
    error,
  } = useQuery<Article>({
    queryKey: articleQueryKey,
    queryFn: () => fetchByEndpoint<Article>(`/api/article/${articleSlug}`),
    initialData: initialArticle,
  });

  usePageView({
    isLoading,
    error,
    data: article,
  });

  useEffect(() => {
    const lastEvent = EventLog.getLastEvent("link") as { content_type: string | null } | null;

    if (asPath.includes("zoek=") || initialArticle?.sponsor || lastEvent?.content_type?.includes("sponsored")) return;
    if (initialAds && !window.dpgPreviousRequest) {
      window.dpgPreviousRequest = initialAds.requestBody;
      window.eventBus.dispatch("triggerAd", { hasInitialAds: true });
      return;
    }

    fetchDpgAds(asPath).then((data) => {
      if (data) {
        injectSnippet(data.html);
        window.eventBus.dispatch("triggerAd", {});
      }
    });
  }, [initialAds, asPath, initialArticle]);

  useNoticeError(error, { queryKey: articleQueryKey.join(","), articleSlug });

  if (!article && !isLoading) return <OopsPage is404={!isError} />;
  const adHtml = parse(initialAds?.html || "");

  return (
    <>
      <Head>{adHtml}</Head>
      <>
        <ArticleMetadata article={article} />
        <ArticleContent article={article} isLoading={isLoading} />
      </>
    </>
  );
};

ArticlePage.getInitialProps = async ({
  req,
  res,
  query,
  dynamoDBUtils,
}: NextPageContext & { dynamoDBUtils: { getItemBySlug: <T>(tableName: string, slug: string) => T } }) => {
  if (!res || !req) return {};

  const slugs = Array.isArray(query.slugs) ? query.slugs : [];
  const articleSlug = slugs[slugs.length - 1];

  try {
    const initialArticle = await dynamoDBUtils.getItemBySlug<Article>(tableNames.article, `${articleSlug}`);
    if (initialArticle) {
      const [currentUrl] = req.url?.split("?") || [];
      const initialAds = !initialArticle.sponsor ? await fetchDpgAds(currentUrl) : null;

      if (initialArticle.redirectUrl || currentUrl !== initialArticle.url) {
        return createRedirectResponse({ res, query, url: initialArticle.redirectUrl || initialArticle.url });
      }

      return { initialArticle, initialAds };
    }
    res.statusCode = 404;
  } catch (error) {
    res.statusCode = 500;
  }
  return {};
};

export default ArticlePage;
